import {useCallback, useEffect, useRef, useState} from 'react';
import {usePlayerStore} from '@common/player/hooks/use-player-store';
import {usePlayerActions} from '@common/player/hooks/use-player-actions';
import axios from 'axios';

export function useTrackPlayTracker() {
  // States
  const [currentTrackId, setCurrentTrackId] = useState<string | number | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isSending, setIsSending] = useState(false);

  // Refs
  const playStartTime = useRef<number | null>(null);
  const hasMetPlayCondition = useRef<boolean>(false);

  // Player hooks
  const {getState} = usePlayerActions();
  const duration = usePlayerStore(s => s.mediaDuration);
  const isPlaying = usePlayerStore(s => s.isPlaying);
  const currentTrack = usePlayerStore(s => getState().cuedMedia?.id);
  const currentTime = usePlayerStore(s => s.getCurrentTime());

  // Reset tracking when track changes
  useEffect(() => {
    if (currentTrackId !== currentTrack) {
      setCurrentTrackId(currentTrack ?? null);
      playStartTime.current = isPlaying ? Date.now() : null;
      setElapsedTime(0);
      hasMetPlayCondition.current = false;
    }
  }, [currentTrack, currentTrackId, isPlaying]);

  const shouldSendPlayData = useCallback(() => {
    if (!currentTrackId || !isPlaying || isSending || hasMetPlayCondition.current) {
      return false;
    }

    // For tracks longer than 60 seconds
    if (duration >= 60 && elapsedTime >= 60) {
      return true;
    }

    // For tracks shorter than 60 seconds
    if (duration < 60 && elapsedTime >= duration) {
      return true;
    }

    return false;
  }, [currentTrackId, isPlaying, isSending, duration, elapsedTime]);

  const sendPlayData = useCallback(async () => {
    if (!shouldSendPlayData()) return;

    setIsSending(true);

    try {
      const response = await axios.post('api/v1/valid-plays', {
        track_id: currentTrackId,
        played_at: new Date().toISOString(),
        platform: "None",
        device: 'None',
        browser: navigator.userAgent,
        location: 'None'
      });

      hasMetPlayCondition.current = true;
      console.log('Valid play data sent successfully', response);
    } catch (error) {
      console.log('Error sending valid play data', error);
    } finally {
      setIsSending(false);
    }
  }, [currentTrackId, shouldSendPlayData]);

  // Timer and elapsed time tracking
  useEffect(() => {
    if (!isPlaying || !currentTrackId) {
      setElapsedTime(0);
      playStartTime.current = null;
      return;
    }

    const timer = setInterval(() => {
      setElapsedTime(prev => {
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPlaying, currentTrackId]);

  // Check conditions and send play data
  useEffect(() => {
    if (shouldSendPlayData()) {
      sendPlayData();
    }
  }, [elapsedTime, shouldSendPlayData, sendPlayData]);

  // Reset tracking when track restarts
  useEffect(() => {
    if (isPlaying && currentTime < 1) {
      hasMetPlayCondition.current = false;
      setElapsedTime(0);
    }
  }, [currentTime, isPlaying]);

  return {
    elapsedTime,
    currentTrackId,
    isPlaying,
    duration
  };
}
